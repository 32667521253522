import React, { useState } from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { PaperCard } from 'components/Card';
import { EXTERNAL, paths } from 'routes';
import { FormProvider, TextField } from 'components/Form';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { loginSchema } from '../validation';
import { useAuth } from 'lib/auth';
import InputAdornment from '@mui/material/InputAdornment';
import { IconWrapper } from 'components/Icon';
import { YellowMessageBar } from 'components/YellowMessageBar';
import { RedMessageBar } from 'components/RedMessageBar';

export const LoginForm = ({
  onSuccess,
  values,
  accountID,
  companyID,
  registrationFlow = false,
  existingUser = false,
  hasUsers = true,
  status = '',
  msg = '',
  acceptInvitation = false,
}) => {
  const [data, setData] = useState({ companyEmail: values?.companyEmail ?? '', password: '' });
  const { login, register } = useAuth();
  const [redMsg, setRedMsg] = useState('');
  const [message, setMessage] = useState(
    msg
      ? msg
      : !registrationFlow
      ? ''
      : existingUser
      ? 'We have detected that the e-mail you entered is already in use, and that you have access to the company. Please log in.'
      : 'We have identified that your e-mail already in use, please log in to proceed with registration.'
  );

  const methods = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(loginSchema),
    values: data,
  });

  const onSubmit = async (data) => {
    try {
      methods.setError(null);
      setMessage('');
      await login(data, hasUsers);

      if (registrationFlow && !existingUser) {
        values.accountID = accountID;
        values.companyID = companyID;
        values.status = status;
        await register(values, false);
      }

      onSuccess();
    } catch (error) {
      setRedMsg(error.message);
      methods.setError('password', { type: 'custom', message: error.message });
    }
  };

  const iconAdornment = registrationFlow
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <IconWrapper
              icon="lockClosed"
              sx={{
                svg: {
                  width: 16,
                  height: 16,
                },
              }}
            />
          </InputAdornment>
        ),
      }
    : {};

  const emailValue = registrationFlow ? values.companyEmail : data.companyEmail;
  //const message = existingUser ? "We have detected that the e-mail you entered is already in use, and that you have access to the company. Please log in." : "We have identified that your e-mail already in use, please log in to proceed with registration.";
  const titleMargin = registrationFlow || redMsg ? 0.5 : 3;

  return (
    <PaperCard px={{ xs: 2, sm: 4 }} width={{ xs: 1, sm: 368 }} maxWidth={368}>
      <Stack >
        <Typography variant="h5" sx={{ mb: titleMargin }}>
          Log in
        </Typography>
        {message && <YellowMessageBar message={message} />}
        {redMsg && <RedMessageBar message={redMsg} />}

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack>
            <TextField
              name="companyEmail"
              label="Company email"
              placeholder="Please enter your email"
              required={true}
              type="email"
              value={emailValue}
              onChange={(e) => setData({ ...data, companyEmail: e.target.value })}
              sx={{ mb: 2 }}
              fullWidth
              disabled={registrationFlow}
              InputProps={iconAdornment}
            />
            <TextField
              name="password"
              label="Password"
              placeholder="Please enter your password"
              required={true}
              type="password"
              value={data.password}
              onChange={(e) => setData({ ...data, password: e.target.value })}
              error={Boolean(methods?.formState?.errors?.password)}
              helperText={methods?.formState?.errors?.password?.message}
              sx={{ mb: 0.5 }}
              fullWidth
            />

            <Typography
              component={Link}
              to={paths.auth.forgotPassword}
              variant="caption"
              fontWeight="bold"
              sx={({ palette }) => ({
                color: `${palette.primary.main} !important`,
              })}
            >
              Forgot password?
            </Typography>

            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 0.5 }}
            >
              Log in
            </Button>

            <Stack
              align="center"
              gap={1.5}
              sx={{
                a: {
                  color: 'inherit',
                  fontWeight: 700,
                  textTransform: 'none',
                },
              }}
            >
              <Typography variant="caption" sx={{ fontWeight: 400, color: "text.primary" }}>
                By continuing, you agree to Delegon’s{' '}
                <a href={EXTERNAL.TERMS_OF_SERVICE}  target='_blank' rel="noreferrer">Terms of Service</a>{' and '}
                <a href={EXTERNAL.PRIVACY_POLICE}  target='_blank' rel="noreferrer">Privacy Policy</a>
              </Typography>

              <Divider sx={{ width: 1 }} />

              <Typography variant="caption" sx={{ fontWeight: 400, color: "text.primary" }}>
                Don't have an account? <Link to={paths.auth.register}>Sign up</Link>
              </Typography>
            </Stack>
          </Stack>
        </FormProvider>
      </Stack>
    </PaperCard>
  );
};
