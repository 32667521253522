import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { useGetRelatedCompanies } from '../api/getRelatedCompanies';

import { RelatedCompaniesListItem } from './RelatedCompaniesListItems';
import { Pagination } from 'components/Pagination';
import { Search } from 'components/Search';
import { useDebouncedCallback } from 'use-debounce';
import { NotFound } from 'components/NotFound/NotFound';
import { useAuth } from 'lib/auth';

export const CompaniesTab = ({ currentTab, companyType }) => {
  const companiesTabName = companyType === "buyer" ? "suppliers" : "customers";
  const { account } = useAuth();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [includeInactive, setIncludeInactive] = useState(!account.super_admin);
  const [loading, setLoading] = useState(false);

  const labelName = companyType === "buyer" ? "Suppliers" : "Buyers";

  const {
    data: { companies, total },
    refetch: refetchCompanies,
    isLoading,
  } = useGetRelatedCompanies(id, {
    companyType,
    search,
    includeInactive,
    page: page - 1,
    queryOptions: { onSettled: () => setLoading(false), enabled: currentTab === companiesTabName },
  });

  const handlePageChange = (_, page) => setPage(page);
  const handleIncludeInactiveChange = () => setIncludeInactive(!includeInactive);
  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    if (!loading) setLoading(true);
    debouncedSearchChange(search);
  };

  useTabReset();
  useRefetchCompanies();

  if (currentTab !== companiesTabName) return null;

  return (
    <>
      <Stack gap={3} height={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width={1}>
          <Search handleSearchChange={handleSearchChange} sx={{minWidth:200, width: 270}} />
          {account.super_admin && (
            <>
          <FormGroup sx={{ flex: '1 1 300px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={includeInactive}
                  onChange={handleIncludeInactiveChange}
                />
              }
              label="Include inactive"
            />
          </FormGroup>
          </>
          )}
        </Stack>
        <Divider />

        <Stack gap={1} flex={1}>
          {/* !isLoading && */ (!companies?.length || companies?.length === 0) && (
            <NotFound
                title={`No related ${labelName} found`}
                caption={
                search
                    ? 'Please try another search term'
                    : ''
                }
                wrapperSx={{ mt: 19 }}
            />
          )}

          {companies.map(( company) => (
            <RelatedCompaniesListItem
              key={company.company_id}
              {...company}
            />
          ))}
        </Stack>

        <Stack direction="row" justifyContent="center">
          <Pagination handlePageChange={handlePageChange} itemCount={total} />
        </Stack>
      </Stack>
    </>
  );

   function useRefetchCompanies() {
    useEffect(() => {
      refetchCompanies();
    }, [page, includeInactive, search]);
  }

  function useTabReset() {
    useEffect(() => {
      if (currentTab !== companiesTabName) {
        setPage(1);
        setSearch('');
        setIncludeInactive(!account.super_admin);
      }
    }, [currentTab]);
  }
};
