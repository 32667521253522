import { axios } from 'lib/axios';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'lib/auth';


const getByIDRequest = async (id, companyID) => {
  try {
    return await axios.get(`/api/dps/${id}`, {headers: {"company_id": companyID}});
  } catch (error) {
    if (error?.response?.data.description === "record not found") {
      throw new Error("Object doesn't exist");
    }
    
    throw new Error("You don't have permissions to see this object");
  }
  // return await axios.get(`/api/dps/${id}`, {headers: {"company_id": companyID}});
};

export const useGetByID = (id, config = {}) => {
  const { user } = useAuth();
  const configOptions = {select: ({ data }) => data, retry: false, ...config}
  return useQuery({
    queryKey: ['dps'],
    queryFn: () => getByIDRequest(id, user.Company.company_id),
    ...configOptions
  });
};
