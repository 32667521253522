import React, { useEffect, useState } from 'react';
import { Divider, Stack, Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { NotFound } from 'components/NotFound/NotFound';
import { useDebouncedCallback } from 'use-debounce';
import { Search } from 'components/Search';
import { Pagination } from 'components/Pagination';

import { useTenderFiles } from '../api/getTendersFiles';
import { FileListItem } from './FileListItem';
import { downloadFile, donwloadFileFromURL } from '../api/downloadFile';
import { FileUploadModal } from './FileUploadModal';
import { useFilePreview } from '../api/previewFile';
import { PDFViewer } from 'components/PDFViewer/PDFViewer';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { useRemoveFile } from '../api/removeFile';
import { ImageViewer } from 'components/ImageViewer/ImageViewer';
import { FilesPremiumBanner } from '../../subscriptions/components/FilesPremiumBanner';
import { useTenderFilesCount } from '../api/getTendersFilesCount';

import { useAuth } from 'lib/auth';

const filesTabName = 'files';

export const FilesTab = ({ currentTab }) => {
  const { id } = useParams();
  const { account, user } = useAuth();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [currentFilePreviewId, setCurrentFilePreviewId] = useState(null);
  const [markedForDelete, setMarkedForDelete] = useState(null);
  const [markedForEdit, setMarkedForEdit] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    data: { files, total },
    refetch: refetchFiles,
  } = useTenderFiles(id, {
    search,
    page: page - 1,
    queryOptions: { onSettled: () => setLoading(false), enabled: currentTab === filesTabName },
  });
  const {
    data: { url, name, MimeType },
  } = useFilePreview(id, currentFilePreviewId);

  const {
    //@ts-ignore
    refetch: refetchSuppliersCount,
  } = useTenderFilesCount(id);

  const handlePageChange = (_, page) => setPage(page);
  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    if (!loading) setLoading(true);
    debouncedSearchChange(search);
  };

  const onFileRemoval = () => {
    refetchFiles();
    setMarkedForDelete(null);
    refetchSuppliersCount();
  };
  const handleMutation = () => {
    refetchFiles();
    if (markedForEdit) setMarkedForEdit(null);
    refetchSuppliersCount();
  };

  const { mutateAsync: removeFile } = useRemoveFile(onFileRemoval);

  useTabReset();
  useRefetch();

  if (currentTab !== filesTabName) return null;

  if (!account.super_admin) {
    if (!user.Company.is_premium || user.Company.subscription_level !== 'gold') {
      return <FilesPremiumBanner count={total} handleSuccess={() => {}} />;
    }
  }

  return (
    <>
      <ImageViewer
        url={url}
        open={!!currentFilePreviewId && MimeType.includes('image/')}
        close={() => setCurrentFilePreviewId(null)}
        handleDownload={() => donwloadFileFromURL(url, name)}
      />

      <PDFViewer
        url={url}
        open={!!currentFilePreviewId && MimeType.includes('application/pdf')}
        close={() => setCurrentFilePreviewId(null)}
        handleDownload={() => donwloadFileFromURL(url, name)}
      />
      <DeleteConfirmationModal
        title={'Remove file'}
        markedForDelete={markedForDelete}
        mutateAsync={() => removeFile({ tenderID: id, fileId: markedForDelete?.itemId })}
      >
        <Typography variant="body1" fontWeight={500}>
          Please confirm the removal of the file{' '}
          <span style={{ fontWeight: 'bold' }}>{markedForDelete?.name}.</span>
        </Typography>
      </DeleteConfirmationModal>

      <Stack gap={3} height={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width={1}>
          <Search fullWidth handleSearchChange={handleSearchChange} sx={{minWidth: 200, width: 350}}/>
          <Box sx={{minWidth: 150, width: 200}}>
            <FileUploadModal handleSuccess={handleMutation} markedForEdit={markedForEdit} />
          </Box>
        </Stack>

        <Divider />

        <Stack flex={1} gap={1}>
          {files?.length === 0 && (
            <NotFound
              title="No Files Found"
              caption={
                search
                  ? 'Please try another search term'
                  : 'Click the "Upload File" button to begin'
              }
              wrapperSx={{ mt: 19 }}
            />
          )}

          {files?.length !== 0 &&
            files.map((file) => (
              <FileListItem
                key={file.file_id}
                handleFilePreview={() => setCurrentFilePreviewId(file.file_id)}
                handleDownload={() => downloadFile({tenderID: id, fileId: file.file_id})}
                markForDelete={() => setMarkedForDelete({ name: file.name, itemId: file.file_id })}
                markForEdit={() => setMarkedForEdit({ name: file.name, fileId: file.file_id })}
                tenderID={id}
                {...file}
              />
            ))}
        </Stack>

        <Stack direction="row" justifyContent="center">
          <Pagination handlePageChange={handlePageChange} itemCount={total} />
        </Stack>
      </Stack>
    </>
  );

  function useRefetch() {
    useEffect(() => {
      refetchFiles();
    }, [page, search]);
  }

  function useTabReset() {
    useEffect(() => {
      if (currentTab !== filesTabName) {
        setPage(1);
        setSearch('');
      }
    }, [currentTab]);
  }
};
