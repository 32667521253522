import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { AccordionButton } from 'components/Button';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';

import { useQueryParams } from 'hook/useQueryParams';

import { paths } from 'routes';

import { useTender } from '../api/getTenderById';
import { DetailViewTabList } from '../components/TabList';
import { DetailsTab } from '../components/DetailsTab';
import { BuyersTab } from '../components/BuyersTab';
import { SuppliersTab } from '../components/SuppliersTab';
import { EditForm } from '../components/EditForm';
import { useTenderCompanyCount } from '../api/getTendersCompanyCount';
import { useTenderFilesCount } from '../api/getTendersFilesCount';
import { StatusToggleButton } from '../components/StatusToggleButton';
import { useAuth } from 'lib/auth';
import { FilesTab } from '../components/FilesTab';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';

import { useSnackbar } from 'notistack';

export const DetailsView = () => {
  const queryParams = useQueryParams();
  const { enqueueSnackbar } = useSnackbar();

  const { account, user } = useAuth();
  let { id } = useParams();
  const [tab, setTab] = useState(queryParams?.tab || 'details');  

  const { data: tender, refetch: refetchTender, error, isError, isLoading } =
    useTender(id);
  const {
    //@ts-ignore
    data: { count: buyersCount },
  } = useTenderCompanyCount(id, 'buyer');
  const {
    //@ts-ignore
    data: { count: suppliersCount },
  } = useTenderCompanyCount(id, 'supplier');
  const {
    //@ts-ignore
    data: { count: filesCount },
  } = useTenderFilesCount(id);

  const switchTab = (_, newTab) => setTab(newTab);

  if (isLoading) {
    return <div>Loading</div>
  }

  if (!isLoading && isError && error.message === "Object doesn't exist") {
    return <div>{error?.message}</div>;
  }

  if (!isLoading && isError) {
    return <PremiumBanner message={`This Tender is only available for Premium members. Click here for more information`} handleSuccess={refetchTender} currentLevel={user.Company.subscription_level} />;
  }

  return (
    <ContentLayout>
      <Stack height="100%">
        <BreadCrumbs
          items={[
            // { name: 'Delegon Admin', to: paths.companyProfile },
            { name: 'Tenders', to: paths.tenders },
            { name: tender?.tender_name ?? 'Details View' },
          ]}
        />
        <Box
          height={70}
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Stack>
            {account.super_admin && (
              <Divider
                sx={({ palette }) => ({
                  height: 5,
                  width: 30,
                  bgcolor:
                    tender?.Status === 'active'
                      ? palette.success.light
                      : palette.error.light,
                  borderRadius: 1,
                })}
              />
            )}
            <Typography variant="h3">{tender?.tender_name || ''}</Typography>
          </Stack>

          {account.super_admin && (
            <AccordionButton label="Actions" sx={{ width: 130, zIndex: 10 }}>
              <Stack gap={0.5}>
                <EditForm tender={tender} refetch={refetchTender}/>

                <StatusToggleButton
                  refetch={refetchTender}
                  status={tender?.Status}
                />
              </Stack>
            </AccordionButton>
          )}
        </Box>

        <DetailViewTabList
          value={tab}
          onChange={switchTab}
          tabs={[
            { label: 'Details' },
            { label: 'Buyers', count: buyersCount },
            { label: 'Suppliers', count: suppliersCount },
            { label: 'Files', count: filesCount },
          ]}
        />

        <Stack
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          gap={1}
          height="100%"
        >
          {error && (
            <PremiumBanner message={`This tender is only available for Premium members. Click here for more information`} handleSuccess={refetchTender} currentLevel={user.Company.subscription_level} />
            // <div>{error?.message}</div>
          )}
          {!error && (
            <>
          {/* {tab === "details" && ( */}
            <DetailsTab currentTab={tab} tender={tender} />
          {/* )} */}

          {/* {tab === "buyers" && ( */}
            <BuyersTab
              currentTab={tab}
              mainBuyer={{ Company: tender?.MainBuyer || null }}
            />
          {/* )} */}

          {/* {tab === "suppliers" && ( */}
            <SuppliersTab currentTab={tab} />
          {/* )} */}

          {/* {tab === "files" && ( */}
            <FilesTab currentTab={tab} />
          {/* )} */}
          </>
        )}
        </Stack>
      </Stack>
    </ContentLayout>
  );
};
