import React from 'react';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { IconWrapper } from 'components/Icon';
import { Stack, Typography, Link } from '@mui/material';
import { paths } from 'routes';
// import { Link } from 'react-router-dom';


export const BlueMessageBar = ({ message, href, sx = {}}) => {
  return (
    <>
    {/* <MuiTextField
      name="info"
      required={true}
      value={message}
      sx={{ mb: 3}}
      fullWidth
      multiline={true}
     // maxRows={3}
      // color = "info"
      disabled
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <IconWrapper icon="info" sx={{height: 24, svg: {stroke: "#3B82F6!important", strokeWidth:2}}} />
          </InputAdornment>
        ),
        sx: {
          p: 0,
          backgroundColor: "#ECF3FF!important",
          height: sx.height ? sx.height : 60,
          boxShadow: 25,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "#FFF!important",
            disabled: true,
          },
          '& textarea.MuiInputBase-inputMultiline': {
            padding: "0!important",
            pl: "12px!important",
            color: "#3B82F6!important",      
            WebkitTextFillColor: "#3B82F6!important",
          },
        },
      }}
      // inputProps={{
      //   sx:{
      //     padding: "0!important",
      //     pl: "12px!important",
      //     fontSize:"0.75rem",
      //     lineHeight: "150%",
      //     fontWeight: 600,
      //     color: "#3B82F6!important",      
      //     WebkitTextFillColor: "#3B82F6!important",
          
      //   }
      // }}
    /> */}

    <Stack gap={1} width={1} height={64}
      sx={({ palette }) => ({
        justifyContent: 'flex-start',
        alignItems: 'center',
        bgcolor: "#ECF3FF",
        color: "#3B82F6",
        fontSize: '0.75rem',
        fontWeight: 600,
        mt:2.5,
        mb:2.5,
        cursor: "pointer",
        boxShadow: 25,
        borderRadius: 1,
        borderColor: "#FFF!important",
        flexDirection: "row",
        ...sx,
      })}
    >
      <IconWrapper icon="info" sx={{height: 24, pl: 2, svg: {stroke: "#3B82F6!important", strokeWidth:2}}} />
      <Typography variant="content"
        sx={{
          color: '#3B82F6',
          pl: 1,
          pr: 1,
          fontSize: '0.875rem',
          fontWeight: '500',
        }}
      >
        <Link href={href} underline="none" color="inherit">
          {message}
        </Link>
      </Typography>   
    </Stack>
    </>
  );
};