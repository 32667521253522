import { Button, Stack } from '@mui/material';
import { Modal } from 'components/Modal';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const DeleteConfirmationModal = ({
  title = 'Remove company',
  markedForDelete,
  mutateAsync,
  children,
}) => {
  const { id: frameworkAgreementId } = useParams();

  const [open, setOpen] = useState(false);

  const { itemId } = markedForDelete || {};

  const handleCloseModal = () => setOpen(false);

  const handleConfirm = () => mutateAsync({ frameworkAgreementId, itemId }).then(handleCloseModal);

  useEffect(() => {
    if (markedForDelete) setOpen(true);
  }, [markedForDelete]);

  return (
    <Modal open={open} handleClose={handleCloseModal} title={title}>
      {children}

      <Stack direction="row" justifyContent="center" gap={1}>
        <Button variant="outlined" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          Confirm
        </Button>
      </Stack>
    </Modal>
  );
};
