import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';

const getRelatedCompanies = async (companyID, queryStringParams) => {
  const queryString = qs.stringify({ ...queryStringParams });

  const {
    data: { data: companies, total },
  } = await axios.get(`/api/companies/${companyID}/companies?${queryString}`);

  return { companies, total };
};

const defaultQueryParams = {
  page: 0,
  limit: 10,
  search: '',
  includeInactive: true,
  queryKey: ['companies-related-companies'],
  queryOptions: {},
  companyType: 'supplier',
};

export const useGetRelatedCompanies = (companyID, queryParams = {}) => {
  const { page, limit, search, includeInactive, queryKey, queryOptions, companyType } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    companytype: companyType,
    limit,
    search,
    includeinactive: includeInactive,
    offset: page * limit,
  };

  return useQuery(
    [...queryKey, companyID],
    () => getRelatedCompanies(companyID, queryStringParams),
    {
      select: ({ companies, total }) => ({ companies, total }),
      initialData: { companies: [], total: 0 },
      ...queryOptions,
    }
  );
}