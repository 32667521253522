import React from 'react';
import { AppBar, Box, Stack, Toolbar } from '@mui/material';
// import { ReactComponent as DelegonLogo } from '../../../assets/Delegon_Main_Logo.svg';
import { ReactComponent as DelegonLogo } from 'assets/Delegon_Main_Logo.svg';
import DelegonLogin1 from 'assets/Delegon_Login1.jpg';
import { Head } from 'components/Head';

export const AuthLayout = ({ title = '', children }) => {
  return (
    <>
      <Head title={title} />
      <Stack minHeight="100vh" width="100%" minWidth={{ sm: 435 }}>
        <AppBar position="static" sx={({ shadows }) => ({ boxShadow: shadows[25] })}>
          <Toolbar
            sx={({ palette }) => ({
              bgcolor: '#fff',
              border: `1px solid ${palette.grey[200]}`,
              display: 'flex',
              justifyContent: 'center',
            })}
          >
            <DelegonLogo />
          </Toolbar>
        </AppBar>
        <Box flex={1} display="flex" width={1} maxHeight={"105vh"}>
          <Box flex={1} bgcolor="#d9d9d9" display={{ xs: 'none', lg: 'initial' }}>
            <img
              src={DelegonLogin1}
              alt={'Login'}
              loading="lazy"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
          <Box flex={1}>
            <Box
              px={{ xs: 1, sm: 0 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={1}
            >
              {children}
            </Box>
          </Box>
          {/* <Box flex={1} bgcolor="#d9d9d9" display={{ xs: 'none', lg: 'initial' }}></Box> */}
        </Box>
      </Stack>
    </>
  );
};
