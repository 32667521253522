import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { AccordionButton } from 'components/Button';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';

import { useQueryParams } from 'hook/useQueryParams';

import { paths } from 'routes';

import { useGetCompanyById } from '../api/getCompanyById';
import { DetailViewTabList } from 'components/TabList';
import { FrameworkAgreementsTab } from '../components/FrameworkAgreementsTab';
import { CompaniesTab } from '../components/CompaniesTab';
import { StatusToggleButton } from '../components/StatusToggleButton';
import { useAuth } from 'lib/auth';

import { useRelatedCompaniesCount } from '../api/getRelatedCompaniesCount';
import { useCompanyFrameworkAgreementsCount } from '../api/getCompanyFrameworkAgreementsCount';
import { SquareImage } from '../components/SquareImage';
import { LogoUpload } from '../components/LogoUpload';
import { useCompanyUsersCount } from '../api/getCompanyUsersCount';

import { UsersTab } from '../components/UsersTab';
import { BannerImage } from '../components';
import { Subscription } from '../components/Subscription';

export const DetailsView = ({ companyType }) => {
  const queryParams = useQueryParams();
  const { account, user } = useAuth();
  let { id } = useParams();
  const [tab, setTab] = useState(queryParams?.tab || 'agreements');

  const { data: company, refetch: refetchCompany } = useGetCompanyById(id);

  const {
    //@ts-ignore
    data: { count: relatedCompaniesCount },
  } = useRelatedCompaniesCount(id, companyType);
  const {
    //@ts-ignore
    data: { count: activeFACount },
  } = useCompanyFrameworkAgreementsCount(id, 'active');
  const {
    //@ts-ignore
    data: { count: expiredFACount },
  } = useCompanyFrameworkAgreementsCount(id, 'expired');
  const {
    //@ts-ignore
    data: { count: usersCount },
  } = useCompanyUsersCount(id);

  const labelName = companyType === 'buyer' ? 'Suppliers' : 'Customers';
  const breadCrumbslabelName = companyType === 'buyer' ? 'Buyers' : 'Suppliers';

  const switchTab = (_, newTab) => setTab(newTab);

  return (
    <ContentLayout>
      <Stack height="100%" width={1}>
        <BreadCrumbs
          sx={{ px: 4 }}
          items={[
            {
              name: breadCrumbslabelName,
              to: companyType === 'buyer' ? paths.buyers : paths.suppliers,
            },
            { name: company?.Name ?? 'Details View' },
          ]}
        />
        <BannerImage banner={company?.background_image_url} bannerChangable refetch={refetchCompany} />

        <Stack direction="row">
          <SquareImage image={company?.square_logo_url || ''} />

          <Stack flex={1}>
            <Box
              px={4}
              height={70}
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Stack position="relative" flexDirection="row">
                <Stack>
                  {account.super_admin && (
                    <Divider
                      sx={({ palette }) => ({
                        height: 5,
                        width: 30,
                        bgcolor:
                          company?.Status === 'active'
                            ? palette.success.light
                            : palette.error.light,
                        borderRadius: 1,
                      })}
                    />
                  )}
                  <Typography variant="h3">{`${company?.Name} (${company?.VAT})` || ''}</Typography>
                </Stack>
              </Stack>

              {(account.super_admin || user.Company?.company_id.toString() === id) && (
                <AccordionButton label="Actions" sx={{ width: 130, zIndex: 10, top:"20px" }}>
                  <Stack gap={0.5}>
                    <>
                      {account.super_admin && (
                        <StatusToggleButton refetch={refetchCompany} status={company?.Status} />
                      )}
                      <LogoUpload />
                      <Subscription currentLevel={company?.subscription_level} isSuperAdmin={account.super_admin} company={company}/>
                    </>
                  </Stack>
                </AccordionButton>
              )}
            </Box>
          </Stack>
        </Stack>

        <DetailViewTabList
          value={tab}
          onChange={switchTab}
          tabs={[
            { label: 'Agreements', count: activeFACount + expiredFACount },
            { label: labelName, count: relatedCompaniesCount },
            { label: 'Users', count: usersCount },
          ]}
        />

        <Stack
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          gap={1}
          height="100%"
        >
          <FrameworkAgreementsTab currentTab={tab} />

          <CompaniesTab currentTab={tab} companyType={companyType} />

          <UsersTab currentTab={tab} />
        </Stack>
      </Stack>
    </ContentLayout>
  );
};
