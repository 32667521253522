import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, TextField } from 'components/Form';
import { Button, Stack, Typography } from '@mui/material';
import { PaperCard } from 'components/Card';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { forgotPasswordSchema } from '../validation';
import { useAuth } from 'lib/auth';

export const ForgotPasswordForm = ({ onSuccess }) => {
  const { checkExistance, forgotpassword } = useAuth();
  const [companyEmail, setCompanyEmail] = useState('');

  const methods = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(forgotPasswordSchema),
    values: { companyEmail: companyEmail },
  });

  const onSubmit = async () => {
    try {
      methods.setError(null);
      const result = await checkExistance(companyEmail);
      if (result.Account === false && result.Company === false && result.User === false) {
        throw new Error(`User with email ${companyEmail} does not exist.`);
      }

      await forgotpassword(companyEmail);

      onSuccess();
    } catch (error) {
      methods.setError('companyEmail', { type: 'custom', message: error.message });
    }
  };

  const titleMargin = 3;
  return (
    <PaperCard width={{ xs: 1, sm: 368 }} maxWidth={{ sm: 368 }}>
      <Stack>
        <Typography variant="h5" sx={{ mb: titleMargin }}>
          Forgot Password
        </Typography>

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack>
            <TextField
              name="companyEmail"
              label="Company email"
              placeholder="Please enter your email"
              required={true}
              type="email"
              value={companyEmail}
              onChange={(e) => {
                setCompanyEmail(e.target.value);
              }}
              sx={{ mb: 2 }}
              fullWidth
            />

            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 0.5 }}
            >
              Send password reset email
            </Button>
          </Stack>
        </FormProvider>
      </Stack>
    </PaperCard>
  );
};
