import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from 'routes';
import { useAuth } from 'lib/auth';
import { ConfirmationSentForm } from '../components/ConfirmationSentForm';
import { LoginForm } from '../components/LoginForm';
import { RequestAccessForm } from '../components/RequestAccessForm';

export const FinishRegistrationForm = ({ handleChange, values, setState }) => {  
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ data, setData ] = useState({})
  const { checkExistance } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const verify = async () => {      
      try {
        const result = await checkExistance(values.companyEmail, values.vat);
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    verify();
  }, [])

  const { Account, AccountID, Company, CompanyID, User } = data;
  values.accountId = AccountID;
  values.companyID = CompanyID;

  return (
    <>
      {/* no Account, no Company, no User - create everything and send confirmation email */}
      {!loading && !Account && !User && !Company && (
        <ConfirmationSentForm 
          values={ values }
        />
      )}
      {/* Account exists, no Company, no User - login form opened. After login create Company and User */}
      {!loading && Account && !User && !Company && (
        <LoginForm 
          values={ values }
          registrationFlow={ true }
          hasUsers={ false }
          accountID={ AccountID }
          status={ "active" }
          onSuccess={() => navigate(paths.homepage)}
          msg={"We have identified that your e-mail already in use, please log in to proceed with registration."}
        />
      )}
      {/* Account, Company and User exist - login form opened. No action after login */}
      {!loading && Account && User && Company && (
        <LoginForm 
          values={ values }
          registrationFlow={ true }
          existingUser={ true }
          onSuccess={() => navigate(paths.homepage)}
          msg={"We have detected that the e-mail you entered is already in use, and that you have access to the company. Please log in."}
        />
      )}
      {/* Company and Account exist, but no User (Person doesn't have access to that company) - login form opened. After login, redirect to request access form.
      After sending request, create User */}
      {!loading && Company && Account && !User /* && SameDomain */ && (
        <LoginForm 
          values={ values }
          registrationFlow={ true }
          existingUser={ true }
          hasUsers={ false }
          accountID={ AccountID }
          companyID={ CompanyID }
          onSuccess={() => navigate(paths.requestAccess, { state: {values} })}
          msg={"We have identified that your e-mail already in use, please log in to proceed with registration."}
        />
      )}
      {/* Company exists, no Account, no User, same domain - confirmation sent form opened. After confirming email and login, request access form is needed*/}
      {!loading && Company && !Account && !User /* && SameDomain */ && (
        <RequestAccessForm values={ values } createFirebaseUser={ true } />
      )}
      {/* Company exists, no User and email domain is different than from admin - Company exists form opened. Return to home action */}
     {/*  {!loading && Company && !User && !SameDomain && (
        <CompanyExistsForm 
          values={ values }
        />
      )} */}
    </>
  );
};
