import { useQuery } from 'react-query';
import qs from 'qs';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  page: 0,
  limit: 10,
  search: '',
  includeInactive: true,
  queryKey: ['tenders-suppliers'],
  queryOptions: {},
};

const getConsortiumGroupSupplierCount = async (tenderID, cgId, companyID) =>
  axios.get(`/api/tenders/${tenderID}/consortium_groups/${cgId}/companies/count`, {headers: {"company_id": companyID}});

const getTenderSuppliers = async (tenderID, queryStringParams, companyID) => {
  const queryString = qs.stringify({ companytype: 'supplier', ...queryStringParams });

  const {
    data: { data, total },
  } = await axios.get(`/api/tenders/${tenderID}/suppliers?${queryString}`, {headers: {"company_id": companyID}});
  // await axios.get(`/api/tenders/${tenderID}/companies?${queryString}`);

  const consortiumGroups = data.filter(({ company_type }) => company_type === 'group');
  const counts = await Promise.all(
    consortiumGroups.map(({ ConsortiumGroup: { cg_id } }) =>
      getConsortiumGroupSupplierCount(tenderID, cg_id, companyID)
    )
  );

  counts.forEach(({ data: { count } }, index) => {
    const cgId = consortiumGroups[index].cg_id;

    const supplierIndex = data.findIndex(({ cg_id }) => cg_id === cgId);

    data[supplierIndex].ConsortiumGroup.supplierCount = count;
  });

  return { suppliers: data, total };
};

export const useTenderSuppliers = (tenderID, queryParams = {}) => {
  const { user } = useAuth();
  const { page, limit, search, includeInactive, queryKey, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    limit,
    search,
    includeinactive: includeInactive,
    offset: page * limit,
  };

  return useQuery(
    [...queryKey, tenderID],
    () => getTenderSuppliers(tenderID, queryStringParams, user.Company.company_id),
    {
      select: ({ suppliers, total }) => ({ suppliers, total }),
      initialData: { suppliers: [], total: 0 },
      ...queryOptions,
    }
  );
};
