import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';

const getRelatedUsers = async (companyID, queryStringParams) => {
  const queryString = qs.stringify({ ...queryStringParams });

  const {
    data: { data: users, total },
  } = await axios.get(`/api/companies/${companyID}/users?${queryString}`);

  return { users, total };
};

const defaultQueryParams = {
  page: 0,
  limit: 10,
  search: '',
  includeInactive: true,
  queryKey: ['companies-related-users'],
  queryOptions: {},
};

export const useGetCompanyUsers = (companyID, queryParams = {}) => {
  const { page, limit, search, includeInactive, queryKey, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    limit,
    search,
    includeinactive: includeInactive,
    offset: page * limit,
  };

  return useQuery(
    [...queryKey, companyID],
    () => getRelatedUsers(companyID, queryStringParams),
    {
      select: ({ users, total }) => ({ users, total }),
      initialData: { users: [], total: 0 },
      ...queryOptions,
    }
  );
}