import qs from 'qs';
import { useQuery } from 'react-query';
import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  page: 0,
  limit: 100,
  search: '',
  includeInactive: true,
  queryKey: ['consortium-group-suppliers'],
  queryOptions: {},
};

const getConsortiumGroupSuppliers = async (faId, cgId, queryStringParams, companyID) => {
  const queryString = qs.stringify({ ...queryStringParams });

  const {
    data: { data, total },
  } = await axios.get(
    `/api/framework_agreements/${faId}/consortium_groups/${cgId}/companies?${queryString}`, {headers: {"company_id": companyID}}
  );

  return { suppliers: data, total };
};

export const useConsortiumGroupSuppliers = (faId, cgId, queryParams = {}) => {
  const { user } = useAuth();
  const { queryKey, queryOptions, page, limit, search, includeInactive } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    limit,
    search,
    includeinactive: includeInactive,
    offset: page * limit,
  };

  return useQuery([...queryKey], () => getConsortiumGroupSuppliers(faId, cgId, queryStringParams, user.Company.company_id), {
    select: ({ suppliers, total }) => ({ suppliers, total }),
    initialData: {
      suppliers: [],
      total: 0,
    },
    ...queryOptions,
  });
};
