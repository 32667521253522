import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateCompanyStatus } from '../api/updateCompanyStatus';
import { AccordionListItem } from 'components/Button';
import { Button, Stack, Typography } from '@mui/material';
import { Modal } from 'components/Modal';

export const StatusToggleButton = ({ status, refetch }) => {
  const { id } = useParams();
  const { mutateAsync } = useUpdateCompanyStatus(refetch);

  const [open, setOpen] = useState(false);

  const handleCloseModal = () => setOpen(false);

  const handleStatusUpdate = () =>
    mutateAsync({
      companyID: id,
      status: status === 'active' ? 'deactivated' : 'active',
    })
      .then(handleCloseModal)
      .catch(() => {});

  return (
    <>
      <AccordionListItem onClick={() => setOpen(true)}>
        {status === 'active' ? 'Deactivate' : 'Activate'}
      </AccordionListItem>

      <Modal open={open} handleClose={handleCloseModal} title="Status update">
        <Typography variant="body1" fontWeight={500}>
          Are you sure you want to set the company status to{' '}
          <span style={{ fontWeight: 'bold' }}>
            {status === 'active' ? 'deactivated' : 'active'}?{' '}
          </span>
        </Typography>

        <Stack direction="row" justifyContent="center" gap={1}>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleStatusUpdate}>
            Confirm
          </Button>
        </Stack>
      </Modal>
    </>
  );
};
