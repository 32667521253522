import React from "react";
import { AuthLayout } from '../components/AuthLayout';
import { VerifyEmailForm } from '../components/VerifyEmailForm';
import { ResetPasswordForm } from '../components/ResetPasswordForm';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { useQueryParams } from 'hook/useQueryParams';

export const Action = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const mode = queryParams.mode;
  const apiKey = queryParams.apiKey;
  const actionCode = queryParams.oobCode;
  const continueURL = queryParams.continueUrl;

  const title = mode === 'resetPassword' ? "Reset Password" : "Verify Email"

  return (
    <AuthLayout title={title}>
      {mode === 'resetPassword' && (
        <ResetPasswordForm apiKey={apiKey} actionCode={actionCode} continueURL={continueURL} onSuccess={() => navigate(paths.homepage)} />
      )}

      {mode === 'verifyEmail' && (
        <VerifyEmailForm apiKey={apiKey} actionCode={actionCode} continueURL={continueURL} onSuccess={() => navigate(paths.homepage)} />
      )}
    </AuthLayout>
  );
};
