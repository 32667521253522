import React from 'react';
import { Box, FormControlLabel, FormGroup, Checkbox as MuiCheckbox, Tooltip } from '@mui/material';
import { useAuth } from 'lib/auth';

const Checkbox = ({ disabled = false, name, label, onChange, checked }) => {
  return (
    <FormGroup>
      <FormControlLabel
        sx={{ mr: 0 }}
        label={label}
        control={
          <MuiCheckbox
            name={name}
            disabled={disabled}
            size="small"
            checked={checked}
            onChange={onChange}
          />
        }
      />
    </FormGroup>
  );
};

export const CheckboxFilter = ({
  adminOnly = false,
  subscriptionTypes = [],
  tooltip = '',
  name,
  label,
  onChange,
  checked,
}) => {
  const {
    account: { super_admin },
    user
  } = useAuth();

  if (adminOnly && !super_admin) {
    return null;
  }

  if (subscriptionTypes.length && !subscriptionTypes.includes(user.Company.subscription_level) && !super_admin) {
    return (
      <Tooltip
        arrow
        title={tooltip}
        placement="top"
        slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14] } }] } }}
      >
        <Box>
          <Checkbox disabled name={name} label={label} onChange={onChange} checked={checked} />
        </Box>
      </Tooltip>
    );
  }

  return <Checkbox name={name} label={label} onChange={onChange} checked={checked} />;
};
