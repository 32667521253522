import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { AccordionButton } from 'components/Button';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';

import { useQueryParams } from 'hook/useQueryParams';

import { paths } from 'routes';

import { useGetByID } from '../api/getById';
import { DetailViewTabList } from '../components/TabList';
import { DetailsTab } from '../components/DetailsTab';
import { BuyersTab } from '../components/BuyersTab';
import { SuppliersTab } from '../components/SuppliersTab';
import { EditForm } from '../components/EditForm';
import { useCompaniesCount } from '../api/getCompaniesCount';
import { useFilesCount } from '../api/getFilesCount';
import { StatusToggleButton } from '../components/StatusToggleButton';
import { useAuth } from 'lib/auth';
import { FilesTab } from '../components/FilesTab';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';

export const DetailsView = () => {
  const queryParams = useQueryParams();

  const { account, user } = useAuth();
  let { id } = useParams();
  const [tab, setTab] = useState(queryParams?.tab || 'details');  

  const { data: dps, refetch: refetchDPS, error, isError, isLoading } =
    useGetByID(id);
  const {
    //@ts-ignore
    data: { count: buyersCount },
  } = useCompaniesCount(id, 'buyer');
  // const {
  //   //@ts-ignore
  //   data: { count: suppliersCount },
  // } = useCompaniesCount(id, 'supplier');
  const {
    //@ts-ignore
    data: { count: filesCount },
  } = useFilesCount(id);

  const switchTab = (_, newTab) => setTab(newTab);

  if (isLoading) {
    return <div>Loading</div>
  }

  console.log(error);
  

  if (!isLoading && isError && error.message === "Object doesn't exist") {
    return <div>{error?.message}</div>;
  }

  if (!isLoading && isError) {
    return <PremiumBanner message={`This DPS is only available for Premium members. Click here for more information`} handleSuccess={refetchDPS} currentLevel={user.Company.subscription_level} />;
  }

  return (
    <ContentLayout>
      <Stack height="100%">
        <BreadCrumbs
          items={[
            { name: 'Dynamic Purchasing Systems', to: paths.dps },
            { name: dps?.dps_name ?? 'Details View' },
          ]}
        />
        <Box
          height={70}
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Stack>
            {account.super_admin && (
              <Divider
                sx={({ palette }) => ({
                  height: 5,
                  width: 30,
                  bgcolor:
                    dps?.Status === 'active'
                      ? palette.success.light
                      : palette.error.light,
                  borderRadius: 1,
                })}
              />
            )}
            <Typography variant="h3">{dps?.dps_name || ''}</Typography>
          </Stack>

          {account.super_admin && (
            <AccordionButton label="Actions" sx={{ width: 130, zIndex: 10 }}>
              <Stack gap={0.5}>
                <EditForm dps={dps} refetch={refetchDPS} />

                <StatusToggleButton
                  refetch={refetchDPS}
                  status={dps?.Status}
                />
              </Stack>
            </AccordionButton>
          )}
        </Box>

        <DetailViewTabList
          value={tab}
          onChange={switchTab}
          tabs={[
            { label: 'Details' },
            { label: 'Buyers', count: buyersCount },
            // { label: 'Suppliers', count: suppliersCount },
            { label: 'Files', count: filesCount },
          ]}
        />

        <Stack
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          gap={1}
          height="100%"
        >
          {error && (
            <PremiumBanner message={`This DPS is only available for Premium members. Click here for more information`} handleSuccess={refetchDPS} currentLevel={user.Company.subscription_level} />
          )}
          {!error && (
            <>
              <DetailsTab currentTab={tab} dps={dps} />
              <BuyersTab
                currentTab={tab}
              />
              {/* <SuppliersTab currentTab={tab} /> */}
              <FilesTab currentTab={tab} />
            </>
          )}
        </Stack>
      </Stack>
    </ContentLayout>
  );
};
