import React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, TextField } from 'components/Form';
import { Button, Stack, Typography } from '@mui/material';
import { RedMessageBar } from 'components/RedMessageBar';
import { PaperCard } from 'components/Card';
import { paths } from 'routes';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { resetPasswordSchema } from '../validation';
import { useAuth } from 'lib/auth';

export const ResetPasswordForm = ({ onSuccess, actionCode }) => {
  const { resetPassword, verifyResetCode, login } = useAuth();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const methods = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(resetPasswordSchema),
    values: { password: password, confirmPassword: confirmPassword },
  });

  const onSubmit = async () => {
    try {
      await resetPassword(actionCode, password);

      const data = { companyEmail: email, password: password };
      await login(data);

      onSuccess();
    } catch (error) {
      methods.setError('confirmPassword', { type: 'custom', message: error.message });
    }
  };

  useEffect(() => {
    const verifyCode = async () => {
      try {
        const data = await verifyResetCode(actionCode);
        if (data) {
          setEmail(data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    verifyCode();
  }, []);

  const titleMargin = 3;
  return (
    <>
      {!loading && (
        <PaperCard width={{ sm: 368 }} maxWidth={{ sm: 368 }}>
          <Stack>
            <Typography variant="h5" sx={{ mb: titleMargin }}>
              Reset Password
            </Typography>
            {error && (
              <>
                <RedMessageBar message={error} />
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2, mb: 0.5 }}
                  href={paths.homepage}
                >
                  Return to home
                </Button>
              </>
            )}

            {!error && (
              <>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                  <Stack>
                    <TextField
                      name="password"
                      label="Password"
                      placeholder="Please enter your password"
                      required={true}
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      error={Boolean(methods?.formState?.errors?.password)}
                      helperText={methods?.formState?.errors?.password?.message}
                      sx={{ mb: 2 }}
                      fullWidth
                    />
                    <TextField
                      name="confirmPassword"
                      label="Confirm Password"
                      placeholder="Please confirm your password"
                      required={true}
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      error={Boolean(methods?.formState?.errors?.confirmPassword)}
                      helperText={methods?.formState?.errors?.confirmPassword?.message}
                      sx={{ mb: 1 }}
                      fullWidth
                    />

                    <Button
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2, mb: 0.5 }}
                    >
                      Reset password
                    </Button>
                  </Stack>
                </FormProvider>
              </>
            )}
          </Stack>
        </PaperCard>
      )}
    </>
  );
};
