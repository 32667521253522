import React from 'react';

import { useCallback, useState } from 'react';

import { AddButton } from 'components/Button';
import { IconWrapper } from 'components/Icon';

import { ShadowCompany } from 'features/companies/components/ShadowCompany';

export const AddCompanyForm = ({ type, refetch }) => {
  const [modalOpen, setModalOpen] = useState(false);


  const CustomEndIcon = () => (
    <IconWrapper
      icon="plus"
      sx={{
        height: 24,
        svg: { width: 16, height: 16, color: 'primary.main', stroke: 'white', strokeWidth: 1.33 },
      }}
    />
  );

  const handleOpenModal = useCallback(() => setModalOpen(true), []);
  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    refetch();
  }, []
  );

  return (
    <>
    <AddButton
      fullWidth
      CustomEndIcon={CustomEndIcon}
      onClick={handleOpenModal}
      sx={{
        svg: { mt: -1 },
      }}
    >
      Add {type}
    </AddButton>

    <ShadowCompany
      open={modalOpen}
      handleClose={handleCloseModal}
      addToList={null}
      type={type}
    />
    </>
  )
}