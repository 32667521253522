import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { useFrameworkAgreementBuyers } from '../api/getFrameworkAgreementBuyers';
import { useRemoveCompany } from '../api/removeCompany';

import { AddCompany } from './AddCompany';
import { BuyerListItem } from './BuyerListItem';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { Pagination } from 'components/Pagination';
import { Search } from 'components/Search';
import { useDebouncedCallback } from 'use-debounce';
import { NotFound } from 'components/NotFound/NotFound';
import { useFrameworkAgreementCompanyCount } from '../api/getFrameworkAgreementCompanyCount';
import { useAuth } from 'lib/auth';

const buyersTabName = 'buyers';

export const BuyersTab = ({ currentTab, mainBuyer }) => {
  const { account } = useAuth();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [markedForDelete, setMarkedForDelete] = useState(null);
  const [includeInactive, setIncludeInactive] = useState(!account.super_admin);
  const [loading, setLoading] = useState(false);

  const {
    data: { buyers },
    refetch: refetchBuyers,
    isLoading,
  } = useFrameworkAgreementBuyers(id, {
    search,
    includeInactive,
    page: page - 1,
    queryOptions: { onSettled: () => setLoading(false), enabled: currentTab === buyersTabName },
  });

  const {
    //@ts-ignore
    refetch: refetchBuyerCount,
  } = useFrameworkAgreementCompanyCount(id, 'buyer');

  const handlePageChange = (_, page) => setPage(page);
  const handleIncludeInactiveChange = () => setIncludeInactive(!includeInactive);
  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    if (!loading) setLoading(true);
    debouncedSearchChange(search);
  };

  const onBuyerRemoval = () => {
    refetchBuyers();
    refetchBuyerCount();
    setMarkedForDelete(null);
  };

  const { mutateAsync } = useRemoveCompany('buyer', onBuyerRemoval);

  useTabReset();
  useRefetchBuyers();

  if (currentTab !== buyersTabName) return null;

  return (
    <>
      {account.super_admin && (
        <DeleteConfirmationModal
          title="Remove buyer"
          markedForDelete={markedForDelete}
          mutateAsync={() =>
            mutateAsync({ frameworkAgreementId: id, facId: markedForDelete?.itemId })
          }
        >
          <Typography variant="body1" fontWeight={500}>
            Please confirm the removal of the company{' '}
            <span style={{ fontWeight: 'bold' }}>{markedForDelete?.name}.</span>
          </Typography>
        </DeleteConfirmationModal>
      )}

      <Stack gap={3} height={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width={1}>
          <Search handleSearchChange={handleSearchChange} sx={{minWidth:200, width: 270}} />
          {account.super_admin && (
            <>
            <FormGroup sx={{ flex: '1 1 300px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={includeInactive}
                    onChange={handleIncludeInactiveChange}
                  />
                }
                label="Include inactive"
              />
            </FormGroup>
          <Box sx={{minWidth: 150, width: 200}}>
            <AddCompany type="buyer" refetch={refetchBuyers} />
          </Box>
          </>
          )}
        </Stack>
        <Divider />

        <Stack gap={1} flex={1}>
          {!isLoading && buyers?.length === 0 && search && (
            <NotFound
              title="No Buyers found"
              caption="Please try another search term"
              wrapperSx={{ mt: 19 }}
            />
          )}

          {buyers.map(({ Company: buyer, fac_id, company_type }, index) => (
            company_type === 'main_buyer' ?
            <BuyerListItem
              key={buyer.Name + index}
              isFirst
              markForDelete={() => null}
              {...buyer}
            />
           : 
           <BuyerListItem
              key={buyer.Name + index}
              markForDelete={() => setMarkedForDelete({ name: buyer.Name, itemId: fac_id })}
              {...buyer}
            />
          ))}
        </Stack>

        <Stack direction="row" justifyContent="center">
          <Pagination handlePageChange={handlePageChange} itemCount={10} />
        </Stack>
      </Stack>
    </>
  );

  function useRefetchBuyers() {
    useEffect(() => {
      refetchBuyers();
    }, [page, includeInactive, search]);
  }

  function useTabReset() {
    useEffect(() => {
      if (currentTab !== buyersTabName) {
        setPage(1);
        setSearch('');
        setIncludeInactive(!account.super_admin);
      }
    }, [currentTab]);
  }
};
