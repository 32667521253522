import React, { useState, useEffect } from 'react';
import { Button, Stack, Typography } from '@mui/material';

import { PaperCard } from 'components/Card';
import { paths } from 'routes';
import { useAuth } from 'lib/auth';

export const ConfirmationSentForm = ({ values }) => {
  const { register } = useAuth();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const createUser = async () => {
      try {
        await register(values);
      } catch (error) {
        //methods.setError("password", { type: "custom", message: error.message });
      } finally {
        setLoading(false);
      }
    };

    createUser();
  }, []);

  return (
    <>
      {!loading && (
        <PaperCard width={{ xs: 1, sm: 368 }} maxWidth={{ sm: 368 }}>
          <Stack>
            <Typography variant="h5" sx={{ mb: 3 }}>
              Almost done!
            </Typography>
            <Stack>
              <Typography variant="body1">We sent a confirmation e-mail to</Typography>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                {`${values?.companyEmail}`}
              </Typography>
              <Typography variant="body1">
                The link will be valid for 48 hours. Please finish your registration to gain access
                to the platform.
              </Typography>

              <Button
                size="large"
                variant="contained"
                color="primary"
                sx={{ mt: 2, mb: 0.5 }}
                href={paths.homepage}
              >
                Return to home
              </Button>
            </Stack>
          </Stack>
        </PaperCard>
      )}
    </>
  );
};
