import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAuth } from 'lib/auth';

export const OrderByDropdown = ({ name, onChange, selected, adminOnly = false, options = [] }) => {
  const {
    account: { super_admin },
    user
  } = useAuth();

  if (adminOnly && !super_admin) return null;

  return (
    <FormControl sx={{ width: 200 }}>
      <InputLabel id="order-by-label">Order By</InputLabel>
      <Select
        name={name}
        labelId="order-by-label"
        id="order-by-select"
        value={selected}
        label="Order By"
        onChange={onChange}
      >
        {options.map(({ value, text, subscriptionType = [], tooltip }) => {
          const disabled = subscriptionType.length && !subscriptionType.includes(user.Company.subscription_level) && !super_admin;
          return (
            <MenuItem key={value} value={value} disabled={disabled}>
              {text + (disabled ? ` (${tooltip})` : '')}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
