import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { useFrameworkAgreementSuppliers } from '../api/getFrameworkAgreementSuppliers';
import { useRemoveCompany } from '../api/removeCompany';

import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { SupplierListItem } from './SupplierListItem';
import { NotFound } from 'components/NotFound/NotFound';
import { useDebouncedCallback } from 'use-debounce';
import { Search } from 'components/Search';
import { Pagination } from 'components/Pagination';
import { ConsortiumGroupListItem } from './ConsortiumGroupListItem';
import { useRemoveConsortiumGroup } from '../api/removeConsortiumGroup';
import { AddSuppliersForm } from './AddSuppliersForm';
import { useFrameworkAgreementCompanyCount } from '../api/getFrameworkAgreementCompanyCount';
import { useAuth } from 'lib/auth';

const suppliersTabName = 'suppliers';

export const SuppliersTab = ({ currentTab }) => {
  const { account } = useAuth();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [markedForDelete, setMarkedForDelete] = useState(null);
  const [includeInactive, setIncludeInactive] = useState(!account.super_admin);
  const [loading, setLoading] = useState(false);

  const {
    data: { suppliers },
    refetch: refetchSuppliers,
  } = useFrameworkAgreementSuppliers(id, {
    search,
    includeInactive,
    page: page - 1,
    queryOptions: { onSettled: () => setLoading(false), enabled: currentTab === suppliersTabName },
  });

  const {
    //@ts-ignore
    refetch: refetchSuppliersCount,
  } = useFrameworkAgreementCompanyCount(id, 'supplier');

  const handlePageChange = (_, page) => setPage(page);
  const handleIncludeInactiveChange = () => setIncludeInactive(!includeInactive);
  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    if (!loading) setLoading(true);
    debouncedSearchChange(search);
  };

  const onSupplierRemoval = () => {
    refetchSuppliers();
    setMarkedForDelete(null);
    refetchSuppliersCount();
  };

  const onSuppliersAdd = () => {
    refetchSuppliers();
    refetchSuppliersCount();
  };
  const { mutateAsync: removeCompany } = useRemoveCompany('supplier', onSupplierRemoval);
  const { mutateAsync: removeConsortiumGroup } = useRemoveConsortiumGroup(onSupplierRemoval);

  useTabReset();
  useRefetch();

  if (currentTab !== suppliersTabName) return null;

  return (
    <>
      {account.super_admin && (
        <DeleteConfirmationModal
          title={markedForDelete?.type === 'supplier' ? 'Remove Supplier' : 'Remove Consortium Group'}
          markedForDelete={markedForDelete}
          mutateAsync={() =>
            markedForDelete?.type === 'supplier'
              ? removeCompany({ frameworkAgreementId: id, facId: markedForDelete?.itemId })
              : removeConsortiumGroup({ frameworkAgreementId: id, cgId: markedForDelete?.itemId })
          }
        >
          <Typography variant="body1" fontWeight={500}>
            Please confirm the removal of the{' '}
            {markedForDelete?.type === 'supplier' ? 'company ' : 'consortium group '}
            <span style={{ fontWeight: 'bold' }}>{markedForDelete?.name}.</span>
          </Typography>
        </DeleteConfirmationModal>
      )}

      <Stack gap={3} height={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width={1}>
          <Search handleSearchChange={handleSearchChange} sx={{minWidth: 200, width: 270}} />
          {account.super_admin && (
            <>
            <FormGroup sx={{ flex: '1 1 300px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={includeInactive}
                    onChange={handleIncludeInactiveChange}
                  />
                }
                label="Include inactive"
              />
            </FormGroup>
            <Box sx={{minWidth: 150, width: 200}}>
              <AddSuppliersForm refetch={onSuppliersAdd} />
            </Box>
            </>
          )}
        </Stack>

        <Divider />

        <Stack gap={1} flex={1}>
          {suppliers?.length === 0 && (
            <NotFound
              title="No Suppliers found"
              caption={
                search
                  ? 'Please try another search term'
                  : account.super_admin ? 'Click the "Add supplier" button to begin' : ''
              }
              wrapperSx={{ mt: 19 }}
            />
          )}
          {suppliers?.length !== 0 &&
            suppliers.map(
              ({ Company: supplier, ConsortiumGroup: cg, fac_id, company_type }, index) =>
                company_type === 'supplier' ? (
                  <SupplierListItem
                    key={supplier.Name + index}
                    markForDelete={() =>
                      setMarkedForDelete({
                        name: supplier.Name,
                        itemId: fac_id,
                        type: 'supplier',
                      })
                    }
                    fa_id = {id}
                    fac_id = {fac_id}
                    {...supplier}
                  />
                ) : (
                  <ConsortiumGroupListItem
                    key={cg.cg_name + index}
                    fa_id={id}
                    supplierList={[]}
                    markForDelete={() =>
                      setMarkedForDelete({
                        name: cg.cg_name,
                        itemId: cg.cg_id,
                        type: 'consortium',
                      })
                    }
                    fac_id = {fac_id}
                    {...cg}
                  />
                )
            )}
        </Stack>

        <Stack direction="row" justifyContent="center">
          <Pagination handlePageChange={handlePageChange} itemCount={10} />
        </Stack>
      </Stack>
    </>
  );

  function useRefetch() {
    useEffect(() => {
      refetchSuppliers();
    }, [page, includeInactive, search]);
  }

  function useTabReset() {
    useEffect(() => {
      if (currentTab !== suppliersTabName) {
        setPage(1);
        setSearch('');
        setIncludeInactive(!account.super_admin);
      }
    }, [currentTab]);
  }
};
