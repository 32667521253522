import React, { useEffect, useState } from 'react';

import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { paths } from 'routes';

import { Search } from 'components/Search';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';
import { NotFound } from 'components/NotFound/NotFound';
import { Pagination } from 'components/Pagination';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';

import { useFrameworkAgreements } from '../api/getAllFrameworkAgreements';
import { useFrameworkAgreementsCount } from '../api/getFrameworkAgreementsCount';
import { FrameworkAgreementListItem } from '../components/FrameworkAgreementListItem';
import { Add } from '../components/Add';
import { useAuth } from 'lib/auth';
import { CheckboxFilter } from 'components/Filter/Inputs/CheckboxFilter';
import { OrderByDropdown } from 'components/Filter/Inputs/OrderByDropdown';

export const List = () => {
  const { account, user } = useAuth();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    includeInactive: !account.super_admin,
    includeExpired: false,
    sortBy: 'dateCreated',
  });
  const [loading, setLoading] = useState(false);

  const [additionalFA, setAdditionalFA] = useState(0);

  const {
    data: { frameworkAgreements, total },
    refetch: refetchFrameworkAgreements,
  } = useFrameworkAgreements({
    search,
    ...filters,
    page: page - 1,
    onSettled: () => setLoading(false),
  });

  const {
    //@ts-ignore
    data: { count: faCount },
  } = useFrameworkAgreementsCount();

  const {
    //@ts-ignore
    data: { count: expiredFACount },
  } = useFrameworkAgreementsCount(true);


  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    if (!loading) setLoading(true);
    debouncedSearchChange(search);
  };
  const handleFilterUpdate = ({ target: { name, value, checked } }) => {
    setFilters((filters) => {
      switch (name) {
        case 'sortBy':
          return { ...filters, [name]: value };

        default:
          return { ...filters, [name]: checked };
      }
    });
  };
  const handlePageChange = (_, page) => setPage(page);

  useFrameworkAgreementRefetch();

  const caption = account.super_admin ? 'Click the "Add Framework Agreement" button to begin' : '';

  useEffect(() => {
    setAdditionalFA(faCount + expiredFACount - total);
  }, [total, faCount, expiredFACount])
  

  return (
    <ContentLayout>
      <Stack height={1}>
        <Stack>
          <BreadCrumbs
            items={[
              { name: '', to: paths.frameworkAgreement },
              { name: `Framework Agrements ${frameworkAgreements && `(${total})`}` },
            ]}
          />
        </Stack>
        <Stack>
          
        </Stack>
        {!account.super_admin && (
          <>
          {(!user.Company.is_premium || user.Company.subscription_level === 'free') && (
            <PremiumBanner message={`There are ${additionalFA} more Framework Agreements available for Premium members. Click here for more information`} handleSuccess={refetchFrameworkAgreements} currentLevel={user.Company.subscription_level} />
          )}

          {(user.Company.is_premium && user.Company.subscription_level !== 'gold') && (
            <PremiumBanner message={`There are ${additionalFA} more framework agreements available for Gold members. Click here for more information`} handleSuccess={refetchFrameworkAgreements} currentLevel={user.Company.subscription_level} />
          )}
              
          </>
        )}

        <Stack
          mt={2}
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          gap={1}
          height="100%"
        >
          <Stack direction="row" gap={2} alignItems="center" pt={1}>
            <Search handleSearchChange={handleSearchChange} sx={{ minWidth: 200, width: 270 }} />

            <Stack direction="row" gap={2} sx={{ flex: '1' }}>
              <CheckboxFilter
                adminOnly
                name="includeInactive"
                onChange={handleFilterUpdate}
                checked={filters.includeInactive}
                label="Inactive"
              />
              <CheckboxFilter
                name="includeExpired"
                onChange={handleFilterUpdate}
                checked={filters.includeExpired}
                label="Expired"
                subscriptionTypes={['gold']}
                tooltip="Available only for Gold members"
              />
            </Stack>
            <OrderByDropdown
              name="sortBy"
              onChange={handleFilterUpdate}
              selected={filters.sortBy}
              options={[
                { value: 'dateCreated', text: 'Date Created' },
                {
                  value: 'expiringDate',
                  text: 'Expiring Date',
                  subscriptionType: ['silver', 'gold'],
                  tooltip: 'premium feature',
                },
              ]}
            />
            {account.super_admin && (
              <Add refetchFrameworkAgreements={refetchFrameworkAgreements} />
            )}
          </Stack>

          {!frameworkAgreements && (
            <Stack
              height="100%"
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h5">No Framework Agreements added</Typography>
              {account.super_admin && (
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    letterSpacing: '-0.14px',
                  }}
                >
                  Click the "Add Framework Agreement" button to begin
                </Typography>
              )}
            </Stack>
          )}

          {frameworkAgreements && (
            <>
            <Stack
              flex={1}
              mt={2}
              py={3}
              bgcolor="background.paper"
              borderTop="1px solid"
              borderColor="border.main"
              gap={1}
            >
              {frameworkAgreements.length === 0 && (
                <NotFound
                  wrapperSx={{ mt: 19 }}
                  title="No Framework Agreements found"
                  caption={caption}
                />
              )}
              {frameworkAgreements.length !== 0 &&
                frameworkAgreements.map((item) => (
                  <FrameworkAgreementListItem key={item.fa_id} {...item} />
                ))}
            </Stack>
            </>
          )}

          <Stack direction="row" justifyContent="center">
            <Pagination handlePageChange={handlePageChange} itemCount={total} />
          </Stack>
        </Stack>
      </Stack>
    </ContentLayout>
  );

  function useFrameworkAgreementRefetch() {
    useEffect(() => {
      refetchFrameworkAgreements();
    }, [
      page,
      // includeInactive,
      filters,
      search,
    ]);
  }
};
