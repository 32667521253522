import { auth } from "lib/firebase"
import { sendEmailVerification, createUserWithEmailAndPassword } from 'firebase/auth';
import { paths } from 'routes';
import { API_URL } from 'configuration';

export const createFirebaseUser = async (data) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, data.companyEmail, data.password);
    const firebaseUID = userCredential.user.uid;
    const actionCodeSettings = {
      url: `${API_URL}${paths.auth.verifyEmail}?firebase_uid=${firebaseUID}`,
    };

    await sendEmailVerification(userCredential?.user, actionCodeSettings);    
    return userCredential;
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      throw new Error("Account already exists.");
    } else if (error.code === 'auth/weak-password') {
      throw new Error("Password is too weak. PLease try again");
    } else if (error.code === 'auth/invalid-email') {
      throw new Error("Invalid e-mail address");
    } else {
      throw error;
    }
  }
}

