import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  page: 0,
  limit: 10,
  search: '',
  includeInactive: true,
  queryKey: ['dps-buyers'],
  queryOptions: {},
};

const getBuyers = async (dpsID, queryStringParams, companyID) => {
  const queryString = qs.stringify({ companytype: 'buyer', ...queryStringParams });

  const {
    data: { data: buyers, total },
  } = await axios.get(`/api/dps/${dpsID}/companies?${queryString}`, {headers: {"company_id": companyID}});

  return { buyers, total };
};

export const useBuyers = (dpsID, queryParams = {}) => {
  const { user } = useAuth();
  const { page, limit, search, includeInactive, queryKey, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    limit,
    search,
    includeinactive: includeInactive,
    offset: page * limit,
  };

  return useQuery(
    [...queryKey, dpsID],
    () => getBuyers(dpsID, queryStringParams, user.Company.company_id),
    {
      select: ({ buyers, total }) => ({ buyers, total }),
      initialData: { buyers: [], total: 0 },
      ...queryOptions,
    }
  );
};
