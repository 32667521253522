import React, { useEffect, useState } from 'react';

import { Checkbox, FormControlLabel, FormGroup, Stack, Typography, Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { paths } from 'routes';

import { Search } from 'components/Search';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';
import { NotFound } from 'components/NotFound/NotFound';
import { Pagination } from 'components/Pagination';

import { useUsers } from '../api/getAllUers';
import { UsersListItem } from '../components/UsersListItems';
//import { Add } from '../components/Add';
import { useAuth } from 'lib/auth';

export const List = ({ companyType }) => {
  const { account } = useAuth();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [includeInactive, setIncludeInactive] = useState(account.super_admin ? false : true);
  const [loading, setLoading] = useState(false);

  const {
    data: { users, total },
    refetch: refetchUsers,
  } = useUsers({
    search,
    includeInactive,
    page: page - 1,
    onSettled: () => setLoading(false),
  });

  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    if (!loading) setLoading(true);
    debouncedSearchChange(search);
  };
  const handleIncludeInactiveChange = () => setIncludeInactive(!includeInactive);
  const handlePageChange = (_, page) => setPage(page);

  useCompaniesRefetch();

  return (
    <ContentLayout>
      <Stack height={1}>
        <Stack>
          <BreadCrumbs
            items={[{ name: '', to: paths.users }, { name: `Users ${users && `(${total})`}` }]}
          />
        </Stack>

        <Stack
          mt={2}
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          gap={1}
          height="100%"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <Search handleSearchChange={handleSearchChange} sx={{ minWidth: 200, width: 270 }} />
            {account.super_admin && (
              <FormGroup sx={{ flex: '1 1 300px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={includeInactive}
                      onChange={handleIncludeInactiveChange}
                    />
                  }
                  label="Include inactive"
                />
              </FormGroup>
            )}
          </Stack>
          <Stack
            flex={1}
            mt={2}
            py={3}
            bgcolor="background.paper"
            borderTop="1px solid"
            borderColor="border.main"
            gap={1}
          >
            {(!users || users.length === 0) && (
              <NotFound
                wrapperSx={{ mt: 19 }}
                title={`No users found`}
                caption={account.super_admin && 'Click the "Invite User" button to begin'}
              />
            )}
            {users.length !== 0 &&
              users.map((item) => <UsersListItem key={item.user_id} {...item} />)}
          </Stack>
          {users && users.length !== 0 && (
            <Stack direction="row" justifyContent="center">
              <Pagination handlePageChange={handlePageChange} itemCount={total} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </ContentLayout>
  );

  function useCompaniesRefetch() {
    useEffect(() => {
      refetchUsers();
    }, [page, includeInactive, search]);
  }
};
