import React, { useEffect, useState } from 'react';

import { Checkbox, FormControlLabel, FormGroup, Stack, Typography, Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { paths } from 'routes';

import { Search } from 'components/Search';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';
import { NotFound } from 'components/NotFound/NotFound';
import { Pagination } from 'components/Pagination';

import { useCompanies } from '../api/getAllCompanies';
import { CompaniesListItem } from '../components/CompaniesListItems';
//import { Add } from '../components/Add';
import { useAuth } from 'lib/auth';

import { AddCompanyForm } from '../components/AddCompanyForm';

export const List = ({ companyType }) => {
  const { account } = useAuth();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [includeInactive, setIncludeInactive] = useState(account.super_admin ? false : true);
  const [loading, setLoading] = useState(false);

  const {
    data: { companies, total },
    refetch: refetchCompanies,
  } = useCompanies({
    search,
    includeInactive,
    page: page - 1,
    onSettled: () => setLoading(false),
    companyType,
  });

  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    if (!loading) setLoading(true);
    debouncedSearchChange(search);
  };
  const handleIncludeInactiveChange = () => setIncludeInactive(!includeInactive);
  const handlePageChange = (_, page) => setPage(page);

  useCompaniesRefetch();

  const labelName = companyType === 'buyer' ? 'Buyers' : 'Suppliers';

  return (
    <ContentLayout>
      <Stack height={1}>
        <Stack>
          <BreadCrumbs
            items={[
              { name: '', to: companyType === 'buyer' ? paths.buyers : paths.suppliers },
              { name: `${labelName} ${companies && `(${total})`}` },
            ]}
          />
        </Stack>

        <Stack
          mt={2}
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          gap={1}
          height="100%"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <Search handleSearchChange={handleSearchChange} sx={{ minWidth: 200, width: 270 }} />
            {account.super_admin && (
              <>
                <FormGroup sx={{ flex: '1 1 300px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={includeInactive}
                        onChange={handleIncludeInactiveChange}
                      />
                    }
                    label="Include inactive"
                  />
                </FormGroup>
                <Box sx={{ minWidth: 150, width: 200 }}>
                  <AddCompanyForm type={companyType} refetch={refetchCompanies} />
                </Box>
              </>
            )}
          </Stack>

          {!companies && (
            <Stack
              height="100%"
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h5">No {labelName} added</Typography>
              {account.super_admin && (
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    letterSpacing: '-0.14px',
                  }}
                >
                  Click the "Add Company" button to begin
                </Typography>
              )}
            </Stack>
          )}

          {companies && (
            <Stack
              flex={1}
              mt={2}
              py={3}
              bgcolor="background.paper"
              borderTop="1px solid"
              borderColor="border.main"
              gap={1}
            >
              {companies.length === 0 && (
                <NotFound
                  wrapperSx={{ mt: 19 }}
                  title={`No ${labelName} found`}
                  caption={account.super_admin && 'Click the "Add Company" button to begin'}
                />
              )}
              {companies.length !== 0 &&
                companies.map((item) => (
                  <CompaniesListItem key={item.company_id} {...item} companyType={companyType} />
                ))}
            </Stack>
          )}
          {companies && companies.length !== 0 && (
            <Stack direction="row" justifyContent="center">
              <Pagination handlePageChange={handlePageChange} itemCount={total} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </ContentLayout>
  );

  function useCompaniesRefetch() {
    useEffect(() => {
      refetchCompanies();
    }, [page, includeInactive, search]);
  }
};
