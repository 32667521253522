import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { PaperCard } from 'components/Card';
import { EXTERNAL, paths } from 'routes';
import { FormProvider, TextField, Select, VATNumber } from 'components/Form';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { companyInfoSchema } from '../validation';
import InputAdornment from '@mui/material/InputAdornment';
import { IconWrapper } from 'components/Icon';
import { validateVAT } from 'lib';

export const CompanyInfoForm = ({ nextStep, handleChange, values }) => {
  const methods = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(companyInfoSchema),
    values: values,
  });

  const onSubmit = async (data) => {
    const isVATNumberValid = await validateVAT('SE', data.vat);
    if (isVATNumberValid) {
      nextStep();
    } else {
      methods.setError('vat', { type: 'custom', message: 'Invalid VAT number' });
    }
  };

  return (
    <PaperCard width={{ xs: 1, sm: 368 }} maxWidth={{ sm: 368 }}>
      <Stack>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Company info
        </Typography>

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack>
            <TextField
              name="companyName"
              label="Company Name"
              placeholder="Please enter company name"
              required={true}
              type="text"
              value={values.companyName}
              onChange={handleChange('companyName')}
              sx={{ mb: 2 }}
              fullWidth
            />
            {/* <Select disabled required fullWidth name="country" label="Country" options={countries} sx={{ mb: 2 }} /> */}
            <TextField
              name="country"
              label="Country"
              placeholder="Please enter company's country"
              required={true}
              type="text"
              value={values.country}
              onChange={handleChange('country')}
              sx={{ mb: 0.5 }}
              fullWidth
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconWrapper
                      icon="lockClosed"
                      sx={{
                        svg: {
                          width: 16,
                          height: 16,
                        },
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <VATNumber
              name="vat"
              label="VAT Number"
              required
              registration={true}
              value={values.vat}
              onChange={handleChange('vat')}
              sx={{ mb: 2 }}
              // fullWidth
              error={Boolean(methods?.formState?.errors?.vat)}
              helperText={methods?.formState?.errors?.vat?.message}
            />

            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 0.5 }}
            >
              Join Delegon
            </Button>
          </Stack>
        </FormProvider>
      </Stack>
    </PaperCard>
  );
};
