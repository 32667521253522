import React from 'react';
import { Stack, Typography } from '@mui/material';

export const MessageBox = ({ message = '', handleOpenModal, sx}) => {
  return (
    <Stack gap={1} width={1} height={52}
      sx={({ palette }) => ({
        justifyContent: 'flex-start',
        bgcolor: palette.purple,
        fontSize: '0.75rem',
        fontWeight: 600,
        mt:2,
        mb:-2,
        cursor: "pointer",
        ...sx,
        // mx:-4,
      })}
    >
      <Typography variant="body1" color="text.primary"
        onClick={handleOpenModal}
        component={"span"}
        sx={{
          mt:2,
          mb:2,
          pl:8
        }}
      >
        {message}
      </Typography>
    </Stack>
  );
};
