import React, { useEffect, useState } from 'react';
import qs from 'qs';
import {
  Box,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import { ContentLayout } from 'components/Layout';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { paths } from 'routes';
import { DeleteConfirmationModal } from '../components/DeleteConfirmationModal';
import { useParams } from 'react-router-dom';
import { Search } from 'components/Search';
import { useDebouncedCallback } from 'use-debounce';
import { Pagination } from 'components/Pagination';
import { NotFound } from 'components/NotFound/NotFound';
import { useCompany } from '../api/getFrameworkAgreementCompanyByFacID';
import { useFrameworkAgreement } from '../api/getFrameworkAgreementById';
import { useAuth } from 'lib/auth';

import { useFrameworkAgreementsSupplierFiles } from '../api/getFrameworkAgreementsSupplierFiles';
import { FileListItem } from '../components/FileListItem';
import { downloadFile, donwloadFileFromURL } from '../api/downloadFile';
import { FileUploadModal } from '../components/SupplierFileUploadModal';
import { useFilePreview } from '../api/previewFile';
import { PDFViewer } from 'components/PDFViewer/PDFViewer';
import { useRemoveFile } from '../api/removeFile';
import { ImageViewer } from 'components/ImageViewer/ImageViewer';
import { FilesPremiumBanner } from '../../subscriptions/components/FilesPremiumBanner';

export const SuppliersFiles = () => {
  const { account, user } = useAuth();
  const { id: faId, facId } = useParams();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [markedForDelete, setMarkedForDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentFilePreviewId, setCurrentFilePreviewId] = useState(null);
  const [markedForEdit, setMarkedForEdit] = useState(null);

  const { data: company } = useCompany(faId, facId);
  const { data: frameworkAgreement } = useFrameworkAgreement(faId);

  const {
    data: { files, total },
    refetch: refetchFiles,
  } = useFrameworkAgreementsSupplierFiles(faId, facId, { search, page: page - 1 });
  const {
    data: { url, name, FileExt, MimeType },
  } = useFilePreview(faId, currentFilePreviewId, facId);

  const onFileRemoval = () => {
    refetchFiles();
    setMarkedForDelete(null);
  };

  const handlePageChange = (_, page) => setPage(page);

  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    if (!loading) setLoading(true);
    debouncedSearchChange(search);
  };

  const handleMutation = () => {
    refetchFiles();
    if (markedForEdit) setMarkedForEdit(null);
  };

  const { mutateAsync: removeFile } = useRemoveFile(onFileRemoval);
  useRefetch();

  return (
    <ContentLayout>
      <Stack height="100%">
        <BreadCrumbs
          items={[
            { name: 'Framework Agrements', to: paths.frameworkAgreement },
            {
              name: frameworkAgreement?.fa_name ?? 'Details View',
              to: `${paths.frameworkAgreement}${faId}`,
            },
            {
              name: 'Suppliers',
              to: `${paths.frameworkAgreement}${faId}?${qs.stringify({ tab: 'suppliers' })}`,
            },
            { name: `${company?.Company?.Name} - Files (${total})` },
          ]}
        />

        {!account.super_admin && (!user.Company.is_premium || user.Company.subscription_level !== 'gold') && (
          <FilesPremiumBanner
            count={total}
            sx={{
              position: "relative",
              mt: 2,
              mx: 0,
              width: '100%',
            }}
          />
        )}
        
        <Stack
          mt={2}
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          gap={3}
          height="100%"
        >
          {(account.super_admin || (user.Company.is_premium && user.Company.subscription_level === 'gold')) && (
          <>
            <ImageViewer
              url={url}
              open={!!currentFilePreviewId && MimeType.includes('image/')}
              close={() => setCurrentFilePreviewId(null)}
              handleDownload={() => donwloadFileFromURL(url, name + FileExt)}
            />

            <PDFViewer
              url={url}
              open={!!currentFilePreviewId && MimeType.includes('application/pdf')}
              close={() => setCurrentFilePreviewId(null)}
              handleDownload={() => donwloadFileFromURL(url, name + FileExt)}
            />
            <DeleteConfirmationModal
              title={'Remove file'}
              markedForDelete={markedForDelete}
              mutateAsync={() =>
                removeFile({ faId: faId, facId: facId, fileId: markedForDelete?.itemId })
              }
            >
              <Typography variant="body1" fontWeight={500}>
                Please confirm the removal of the file{' '}
                <span style={{ fontWeight: 'bold' }}>{markedForDelete?.name}.</span>
              </Typography>
            </DeleteConfirmationModal>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
              width={1}
            >
              <Search handleSearchChange={handleSearchChange} sx={{ minWidth: 200, width: 270 }} />
              {account.super_admin && (
                <>
                  <Box sx={{ minWidth: 150, width: 200 }}>
                    <FileUploadModal handleSuccess={handleMutation} markedForEdit={markedForEdit} />
                  </Box>
                </>
              )}
            </Stack>

            <Divider />

            <Stack gap={1} flex={1}>
              {files?.length === 0 && (
                <NotFound
                  title="No Files found"
                  caption={
                    search
                      ? 'Please try another search term'
                      : account.super_admin
                      ? 'Click the "Add file" button to begin'
                      : ''
                  }
                  wrapperSx={{ mt: 19 }}
                />
              )}

              {files?.length !== 0 &&
                files.map((file) => (
                  <FileListItem
                    key={file.file_id}
                    handleFilePreview={() => setCurrentFilePreviewId(file.file_id)}
                    handleDownload={() =>
                      downloadFile({ faId: faId, facId: facId, fileId: file.file_id })
                    }
                    markForDelete={() =>
                      setMarkedForDelete({ name: file.name, itemId: file.file_id })
                    }
                    markForEdit={() => setMarkedForEdit({ name: file.name, fileId: file.file_id })}
                    {...file}
                  />
                ))}
            </Stack>

            <Stack direction="row" justifyContent="center">
              <Pagination handlePageChange={handlePageChange} itemCount={total} />
            </Stack>
          </>
        )}
        </Stack>
      </Stack>
    </ContentLayout>
  );

  function useRefetch() {
    useEffect(() => {
      refetchFiles();
    }, [page, search]);
  }
};
