import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';

const getCompanyFrameworkAgreementsCount = (companyID, status) => {
  const queryString = qs.stringify({ status });
  return axios.get(
    `/api/companies/${companyID}/framework_agreements/count?${queryString}`
  );
};

export const useCompanyFrameworkAgreementsCount = (companyID, status, options = {}) =>
  useQuery(
    `company-${companyID}-framework-agreements-count-${status}`,
    () => getCompanyFrameworkAgreementsCount(companyID, status),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );