import React, { useCallback, useState } from 'react';
import { SubscribeForm } from './SubscribeForm';
import { ModifyForm } from './ModifyForm';
import { MessageBox } from './MessageBox';

export const PremiumBanner = ({ message = '', handleSuccess = null, currentLevel = "free", sx = {} }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => setModalOpen(true), []);
  const handleCloseModal = useCallback(() => setModalOpen(false), []);

  return (
    <>
      <MessageBox
        message={message}
        handleOpenModal={handleOpenModal}
        sx={sx}
      />

      {currentLevel === "free" && (
        <SubscribeForm
          open={modalOpen}
          handleClose={handleCloseModal}
          handleSuccess={handleSuccess}
        />
      )}

      {currentLevel !== "free" && (
        <ModifyForm
          open={modalOpen}
          handleClose={handleCloseModal}
          handleSuccess={handleSuccess}
          currentLevel={currentLevel}
        />
      )}
      
      
    </>
  );
};
