import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { AccordionButton } from 'components/Button';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';

import { useQueryParams } from 'hook/useQueryParams';

import { paths } from 'routes';

import { useFrameworkAgreement } from '../api/getFrameworkAgreementById';
import { DetailViewTabList } from '../components/TabList';
import { DetailsTab } from '../components/DetailsTab';
import { BuyersTab } from '../components/BuyersTab';
import { SuppliersTab } from '../components/SuppliersTab';
import { EditForm } from '../components/EditForm';
import { useFrameworkAgreementCompanyCount } from '../api/getFrameworkAgreementCompanyCount';
import { useFrameworkAgreementFilesCount } from '../api/getFrameworkAgreementFilesCount';
import { StatusToggleButton } from '../components/StatusToggleButton';
import { useAuth } from 'lib/auth';
import { FilesTab } from '../components/FilesTab';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';

import { useSnackbar } from 'notistack';

export const DetailsView = () => {
  const queryParams = useQueryParams();
  const { enqueueSnackbar } = useSnackbar();

  const { account, user } = useAuth();
  let { id } = useParams();
  const [tab, setTab] = useState(queryParams?.tab || 'details');  

  const { data: frameworkAgreement, refetch: refetchFrameworkAgreement, error, isError, isLoading } =
    useFrameworkAgreement(id);
  const {
    //@ts-ignore
    data: { count: buyersCount },
  } = useFrameworkAgreementCompanyCount(id, 'buyer');
  const {
    //@ts-ignore
    data: { count: suppliersCount },
  } = useFrameworkAgreementCompanyCount(id, 'supplier');
  const {
    //@ts-ignore
    data: { count: filesCount },
  } = useFrameworkAgreementFilesCount(id);

  const switchTab = (_, newTab) => setTab(newTab);

  if (isLoading) {
    return <div>Loading</div>
  }

  if (!isLoading && isError && error.message === "Object doesn't exist") {
    return <div>{error?.message}</div>;
  }

  if (!isLoading && isError) {
    return <PremiumBanner message={`This framework agreement is only available for Premium members. Click here for more information`} handleSuccess={refetchFrameworkAgreement} currentLevel={user.Company.subscription_level} />;
  }

  return (
    <ContentLayout>
      <Stack height="100%">
        <BreadCrumbs
          items={[
            // { name: 'Delegon Admin', to: paths.companyProfile },
            { name: 'Framework Agrements', to: paths.frameworkAgreement },
            { name: frameworkAgreement?.fa_name ?? 'Details View' },
          ]}
        />
        <Box
          height={70}
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Stack>
            {account.super_admin && (
              <Divider
                sx={({ palette }) => ({
                  height: 5,
                  width: 30,
                  bgcolor:
                    frameworkAgreement?.Status === 'active'
                      ? palette.success.light
                      : palette.error.light,
                  borderRadius: 1,
                })}
              />
            )}
            <Typography variant="h3">{frameworkAgreement?.fa_name || ''}</Typography>
          </Stack>

          {account.super_admin && (
            <AccordionButton label="Actions" sx={{ width: 130 }}>
              <Stack gap={0.5}>
                <EditForm frameworkAgreement={frameworkAgreement} refetch={refetchFrameworkAgreement} />

                <StatusToggleButton
                  refetch={refetchFrameworkAgreement}
                  status={frameworkAgreement?.Status}
                />
              </Stack>
            </AccordionButton>
          )}
        </Box>

        <DetailViewTabList
          value={tab}
          onChange={switchTab}
          tabs={[
            { label: 'Details' },
            { label: 'Buyers', count: buyersCount },
            { label: 'Suppliers', count: suppliersCount },
            { label: 'Files', count: filesCount },
          ]}
        />

        <Stack
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          gap={1}
          height="100%"
        >
          {error && (
            <PremiumBanner message={`This framework agreement is only available for Premium members. Click here for more information`} handleSuccess={refetchFrameworkAgreement} currentLevel={user.Company.subscription_level} />
            // <div>{error?.message}</div>
          )}
          {!error && (
            <>
          {/* {tab === "details" && ( */}
            <DetailsTab currentTab={tab} frameworkAgreement={frameworkAgreement} />
          {/* )} */}

          {/* {tab === "buyers" && ( */}
            <BuyersTab
              currentTab={tab}
              mainBuyer={{ Company: frameworkAgreement?.MainBuyer || null }}
            />
          {/* )} */}

          {/* {tab === "suppliers" && ( */}
            <SuppliersTab currentTab={tab} />
          {/* )} */}

          {/* {tab === "files" && ( */}
            <FilesTab currentTab={tab} />
          {/* )} */}
          </>
        )}
        </Stack>
      </Stack>
    </ContentLayout>
  );
};
